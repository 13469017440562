import { FeatureApp, createFeatureAppIntegrator } from "@smart/react-utils";
import "./index.module.scss";
import React from "react";
import { getClientConfig } from "./utils";

const clientConfig = getClientConfig();

createFeatureAppIntegrator({
  App: ({ endpointDirectory: { envName } }) => (
    <React.Fragment>
      <FeatureApp name="example" envName={envName} />
      <FeatureApp name="payment-calculator" envName={envName} />
    </React.Fragment>
  ),
  id: `example-feature-app-integrator`,
  clientConfig,
});
