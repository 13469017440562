import { EndpointDirectoryV3 } from "@smart/endpoint-directory";
import type { EnvNameV3, HeaderV1 } from "@smart/endpoint-directory";
import { ClientConfig } from "@smart/react-utils";

export function getClientConfig(): ClientConfig {
  const url = new URL(window.location.href);
  const languageTag = url.searchParams.get(`languageTag`) || `de-DE`;
  const preview = url.searchParams.get(`preview`) === `false`;
  const envName = (process.env.TARGET as EnvNameV3) || (`dev` as EnvNameV3);
  const marketId = url.searchParams.get(`marketId`) || `de`;

  const endpointDirectory: EndpointDirectoryV3 = {
    //TODO: Not needed but verify if it's needed
    rotatingVerifySecret: undefined,
    envName,
    preview,
  };

  return {
    endpointDirectory,
    locale: {
      marketId,
      languageTag,
    },
  };
}
